<template>
  <div>
    <Footer></Footer>
      <div class="content">
        <div class="content_box">
         <span class="content_box_tilte"> 第九届“大唐杯”全国大学生新一代信息通信技术大赛 官方通知发布</span>
         <div class="content_box_BOX"> 
              <span class="content_inform">官方通知</span>
              <div class="content_text">
                  <p><el-link type="info" @click="openfile1">“大唐杯”全国大学生新一代信息通信技术大赛-投诉检具处理措施</el-link></p>
                  <p><el-link type="info" @click="openfile2">第九届“大唐杯”全国大学生新一代信息通信技术大赛-参赛通知</el-link></p>
                  <p><el-link type="info" @click="openfile3">第九届“大唐杯”全国大学生新一代信息通信技术大赛-大纲</el-link></p>
                  <p><el-link type="info" @click="openfile4">第九届“大唐杯”全国大学生新一代信息通信技术大赛-全国总决赛参赛通知</el-link></p>
                  <p><el-link type="info" @click="openfile5">第九届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛竞赛安排</el-link></p>
                  <p><el-link type="info" @click="openfile6">第九届“大唐杯”全国大学生新一代信息通信技术大赛-省赛竞赛安排</el-link></p>
                  <p><el-link type="info" @click="openfile7">第九届“大唐杯”全国大学生新一代信息通信技术大赛-章程</el-link></p>
                  <p><el-link type="info" @click="openfile8">第九届“大唐杯”全国大学生新一代信息通信技术大赛-证书查询公示</el-link></p>
              </div>
         </div>
        </div>


        <div class="content_box">
         <span class="content_box_tilte"> 第九届“大唐杯”全国大学生新一代信息通信技术大赛 官方文件发布</span>
         <div class="content_box_BOXTOW"> 
              <p class="content_inform">官方文件</p>
              <div class="content_text">
                <span><el-link type="info" @click="openfile1">“大唐杯”全国大学生新一代信息通信技术大赛-投诉检具处理措施</el-link></span>
                  <p><el-link type="info" @click="openfile2">第九届“大唐杯”全国大学生新一代信息通信技术大赛-参赛通知</el-link></p>
                  <p><el-link type="info" @click="openfile3">第九届“大唐杯”全国大学生新一代信息通信技术大赛-大纲</el-link></p>
                  <p><el-link type="info" @click="openfile4">第九届“大唐杯”全国大学生新一代信息通信技术大赛-全国总决赛参赛通知</el-link></p>
                  <p><el-link type="info" @click="openfile5">第九届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛竞赛安排</el-link></p>
                  <p><el-link type="info" @click="openfile6">第九届“大唐杯”全国大学生新一代信息通信技术大赛-省赛竞赛安排</el-link></p>
                  <p><el-link type="info" @click="openfile7">第九届“大唐杯”全国大学生新一代信息通信技术大赛-章程</el-link></p>
                  <p><el-link type="info" @click="openfile8">第九届“大唐杯”全国大学生新一代信息通信技术大赛-证书查询公示</el-link></p>
              </div>
         </div>
        </div>
      </div>
  <Bottom></Bottom>
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import $ from "jquery";
export default {
  name: 'Home',
  components: {
    Footer,
    Bottom
  },
  data(){
    return {

    }
  },
  methods:{
    openfile1(){
      window.open('./static/Ninth/“大唐杯”全国大学生新一代信息通信技术大赛-投诉检具处理措施.pdf')
    },
    openfile2(){
      window.open('./static/Ninth/第九届“大唐杯”全国大学生新一代信息通信技术大赛-参赛通知.pdf')

    },
    openfile3(){
      window.open('./static/Ninth/第九届“大唐杯”全国大学生新一代信息通信技术大赛-大纲.pdf')

    },
    openfile4(){
      window.open('./static/Ninth/第九届“大唐杯”全国大学生新一代信息通信技术大赛-全国总决赛参赛通知.pdf')

    },
    openfile5(){
      window.open('./static/Ninth/第九届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛竞赛安排.pdf')

    },
    openfile6(){
      window.open('./static/Ninth/第九届“大唐杯”全国大学生新一代信息通信技术大赛-省赛竞赛安排.pdf')

    },
    openfile7(){
      window.open('./static/Ninth/第九届“大唐杯”全国大学生新一代信息通信技术大赛-章程.pdf')

    },
    openfile8(){
      window.open('./static/Ninth/第九届“大唐杯”全国大学生新一代信息通信技术大赛-证书查询公示.pdf')

    },
  },
  mounted(){

  },
  created(){

  },
}
</script>

<style scoped lang="scss">
/deep/ .el-table .cell.el-tooltip{
  text-align: left;
}
/deep/.el-link__inner{
  color: #000;
}
/deep/.el-link__inner:hover{
  color: orangered;
}

@import './Ninth.scss';
</style>
